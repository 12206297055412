import { Injectable, inject } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { NotificationType, NotificationsService } from 'angular2-notifications'

@Injectable({
   providedIn: 'root',
})
export class NotificationService {
   private _translate = inject(TranslateService)
   private _notificationsService = inject(NotificationsService)

   notify(type: NotificationType, title?: string, content?: string) {
      if (!title) title = this._translate.instant('notify_default_title_' + type)
      if (!content) content = this._translate.instant('notify_default_content_' + type)
      this._notificationsService.create(title?.trim(), content?.trim(), type)
   }
}
